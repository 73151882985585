import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import HumanQLogo from "../../assets/HumanQLogo.png";
import logoOficial from "../../assets/SoluSingLogoPrincipal.png";
import logoOficialDoc from "../../assets/SoluLogo.png";
import posterLogin from '../../assets/posterLogin.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import videoLogin from "./VideoLogin.mp4";
import videoLoginMovil from "./MovilVersion.mp4";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./stylesLogin.css";
import packageJson from "../../../package.json";
import { getHash } from "../../utils";
import { authentication } from "../../store/actions/AuthenticationActions";
import CapchaComponent from "./newUser/CapchaComponent";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { getFile, GetDataPlanClient, getFileUserActionCustom, resetDocument, resetDocument64, resetDocumentEnlace, resetFile, resetFileFlow, resetStatusStack, getFiles } from "../../store/actions";
import { FileService } from "../../services/FileService";
import { AuthService } from "../../services/AuthService";
import BackdropComponent from "../../components/BackDrop";
import { Alert } from "../../components/Alert";

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  formInput: {
    width: "80%",
    padding: 1,
    '& .MuiInputLabel-root': { color: "black" },
  },
  boxReponsive: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  containerPrincipal: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    boxShadow: 'inset 18.0333px -18.0333px 18.0333px rgba(194, 194, 194, 0.1), inset -18.0333px 18.0333px 18.0333px rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(18.0333px)',
    borderRadius: "3%",
    minWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "89%",
    },
  },
  contenedorLogin: {
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#86DF2D",
    color: "#86DF2D",
  },
  contenedorVideo: {
    zIndex: 2,
  },
  contenedor: {
    zIndex: 1,
  },
  logoDoc: {
    p: 1,
    mt: 2,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    maxWidth: 350,
    [theme.breakpoints.down("md")]: {
      maxWidth: 200,
      marginTop: 15,
    },
  }
}));


export default function LoginComponent(props) {
  const { handleSubmit, register } = useForm();
  const useClient = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Email
  );
  const userData = useSelector((state) => state.Authentication?.items?.UserLoginData);
  let roles = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles
  );

  const query = new URLSearchParams(useLocation().search);
  const emailUser = sessionStorage.getItem('emailUser')
  const email = query.get("Email");
  const questionIdSign = query.get("ActionId");
  const fileIdParam = query.get("FileId");
  const questionId = query.get("uid");
  const classes = useStyles();
  const [idRef, setIdRef] = useState('')

  const [stateCaptcha, setStateCaptcha] = useState(false);
  const [blocked, setBlocked] = useState(true)
  const [dataIPConfig, setDataIPConfig] = useState(null)
  const [loadignState, setLoadignState] = useState(false)
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  // //Redux hooks
  const auth = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    clienteIPRefer()
  }, [])

  const clienteIPRefer = async () => {
    try {
      const authService = AuthService.getInstance();
      const comentData = await authService.getClientIPsWithRefer();
      if (comentData?.data?.data.IsOK == true) {
        if (comentData?.data?.data?.Body.UseCapcha == true) {
          setDataIPConfig(comentData?.data?.data?.Body.UseCapcha)
        } else {
          setDataIPConfig(comentData?.data?.data?.Body.UseCapcha)
          setIdRef(comentData?.data?.data?.Body.ReferNoCapcha)
        }
      }

    } catch (error) {
      console.log('error', error)
    }
  }

  //Acción login
  const handleService = async (formData) => {
    const { username } = formData;
    let data = {
      //Objeto a enviar a API login
      RequestDataInfo: {
        Coordinates: {
          Latitude: "222222",
          Longitude: "1111111",
        },
        SOVersion: "2",
        PhoneBrand: "5525397878",
        PhoneModel: "vr",
        BrowserRefer: "chrome",
        IP: "0.0.0.0",
        MacAddress: "FC:01:7C:45:48:3F",
      },
      ReferId: idRef,
      Tenant: "",
      Username: username, //State del usuario
      // Password: pass, //State de la contraseña
      PasswordHash: getHash(values.password),
    };
    dispatch(authentication(data));
    
  };

  const redirectStage = async () => {
    dispatch(getFile({ FileId: fileIdParam }));
    setLoadignState(false);
    return navigate("/inbox/file-information");
  }

  const redirectFirma = async () => {
    setLoadignState(true);
    try {
      const fileService = FileService.getInstance();
      const dataStage = await fileService.getFileUserActionCustom({
        ActionUid: questionIdSign,
      });
      if (dataStage.data.data.IsOK == false) Alert({
        icon: "error",
        text: dataStage.data.data.Messages,
      });
      dispatch(
        getFileUserActionCustom(dataStage.data.data.Body.FileCustomData)
      );
      setLoadignState(false);
      if (dataStage.data.data.Body?.Pin_OTP) {
        sessionStorage.setItem("OTP", dataStage.data.data.Body.Pin_OTP);
        setLoadignState(false);
        return navigate("/inbox/file-information");
      } else {
        dispatch(
          getFileUserActionCustom(dataStage.data.data.Body.FileCustomData)
        );
        setLoadignState(false);
        return navigate("/inbox/file-information");
      }
    } catch (error) {
      setLoadignState(false);
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (sessionStorage.token) {
      setLoadignState(true);
      if (questionId) {
        navigate(`/inbox/verify-xml?uid=${questionId}`);
        dispatch(
          GetDataPlanClient({
            referenceData: "",
            clientId: null,
            email: useClient,
          })
        );
        setLoadignState(false);
      } else if ( fileIdParam !== null || questionIdSign !== null ) {
        redirecEvaluation();
      } else {
        setLoadignState(false);
        dispatch(resetFileFlow());
        dispatch(resetDocumentEnlace());
        dispatch(resetDocument64());
        dispatch(resetFile());
        dispatch(resetDocument());
        dispatch(resetStatusStack());
        dispatch(
          GetDataPlanClient({
            referenceData: "",
            clientId: null,
            email: useClient,
          })
        );
        if (roles.length === 0) {
          console.log('roles', roles)
          navigate("/inbox/payments");
        } else {
          console.log('roles', roles)
          navigate("/inbox/files");
        }
      }
    }
  }, [auth]); //Valor a evaluar, sí este cambia, el contenido del arrow se ejecutará

  const redirecEvaluation = async () => {
    if (userData?.EnviromentVars?.RedirectRule) {
      try {
        const fileService = FileService.getInstance();
        const fileData = await fileService.getFiles({
          SearchText: "",
          FechaInicio: "",
          FechaFin: "",
          Page: 1,
        });
        if (fileData.Body.CountItems === 1) {
          redirectIds();
        } else {
          dispatch(
            getFiles({
              SearchText: "",
              FechaInicio: "",
              FechaFin: "",
              Page: 1,
            })
          );
          setLoadignState(false);
          return navigate("/inbox/files");
        }
      } catch (error) {
        setLoadignState(false);
        console.log('error', error)
      }
    } else {
      redirectIds()
    }
  }

  const redirectIds = () => {
    if (fileIdParam !== '{FolioSend}' && fileIdParam) {
      redirectStage();
    } else if (questionIdSign) {
      redirectFirma();
      dispatch(
        GetDataPlanClient({
          referenceData: "",
          clientId: null,
          email: useClient,
        })
      );
      setLoadignState(false);
    }
  }

  useEffect(() => {
    if (auth.error !== undefined) {
      setLoadignState(false);
      window?.grecaptcha?.reset();
      setIdRef('');
      clienteIPRefer()
    }
  }, [auth])//


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    // Conditional operator used to make responsive View
    <Fragment>
      <BackdropComponent loading={loadignState || auth?.loading} />
      <div className="contenedor">
        <div className="contenedorVideo">
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <video className="video" src={videoLogin} autoPlay loop muted poster={posterLogin} />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <video className="videoMovil" src={videoLoginMovil} autoPlay loop muted poster={posterLogin} />
          </Box>
        </div>
        <div className="contenedorLogin">
          <Grid className={classes.containerPrincipal}>
            <Grid item xs={10} sm={10} lg={12} md={12}>
              <Box className={classes.boxReponsive}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      textAlign: "center",
                      mt: 2,
                      mb: 2
                    }}
                  >
                    <Box className={classes.logoDoc}>
                      <img src={
                        process.env.REACT_APP_BASENAME === '/FEADevOpsDev/FEA/' ? HumanQLogo :
                          process.env.REACT_APP_BASENAME === '/OBFirmaElectronicaCap/FEA/' ? HumanQLogo :
                            HumanQLogo
                      } alt="Logo" />
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "center", p: 1 }}>
                    <Typography sx={{ color: 'black' }} variant="titulo">
                      Hola, ¡Te damos la bienvenida de nuevo!
                    </Typography>
                  </Box>
                  <form onSubmit={handleSubmit(handleService)}>
                    <Box width={"100%"} sx={{ p: 1 }}>
                      <TextField
                        className={classes.formInput}
                        size="small"
                        defaultValue={emailUser ? emailUser : email ? email : null}
                        label="Correo electrónico"
                        {...register("username", { required: true })}
                        name="username"
                      />
                    </Box>
                    <Box width={"100%"} sx={{ p: 1, mt: 1 }}>
                      <FormControl className={classes.formInput} autoComplete='new-password' size="small" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Contraseña
                        </InputLabel>
                        <OutlinedInput
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }

                          InputProps={{
                            inputProps: {
                              outlinet: 'none',
                            }
                          }}
                          label="Contraseña"
                        />
                      </FormControl>
                      <Box sx={{ p: 1, textAlign: "right", width: '90%' }}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/auth/restartPassword"
                        >
                          <Typography variant="body2" color="primary">
                            ¿Olvidaste tu contraseña?
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                    {dataIPConfig == true ?
                      <Box
                        fullWidth
                        sx={{
                          p: 1,
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          justifyItems: "center",
                        }}
                      >
                        <CapchaComponent setStateCaptcha={setStateCaptcha} setIdRef={setIdRef} setBlocked={setBlocked} />
                      </Box>
                      : null}
                    <Box sx={{ p: 1, mb: 1 }}>
                      <LoadingButton
                        variant="contained"
                        border={1}
                        disabled={idRef === '' || idRef === null}
                        sx={{
                          fontSize: "16px !important",
                          height: 38,
                          fontWeight: 400,
                          width: '80%',
                          letterSpacing: 1.5
                        }}
                        loading={stateCaptcha}
                        type="submit"
                        onClick={() => handleSubmit(handleService)}
                      >
                        Ingresar
                      </LoadingButton>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '10px!important' }}>
                        V. {packageJson.version}
                      </Typography>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box sx={{ display: "flex", mt: 4 }}>
              <Typography
                variant="body"
                color="#FFF"
                sx={{
                  display: "flex", alignItems: 'center'
                }}
              >
                Derechos reservados por
                <Box sx={{ width: 150, ml: 1, mr: 1 }}>
                  <a href="https://solusign.mx/" target="_blank">
                    <img alt="logoSolusign" src={logoOficialDoc} />
                  </a>
                </Box>
                {/* &#xA9; {new Date().getFullYear()} DocSolutions */}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </Fragment>
  );
}
