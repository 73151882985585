import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box } from "@mui/system";
import phoneCodigo from "../../../../assets/OTP.gif";
import React, { Fragment, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { notificationPINStageFile } from "../../../../store/actions/FileFlowActions";
import { getFile } from "../../../../store/actions";
import { SignatureService } from "../../../../services/SignatureService";
import { Alert } from "../../../../components/Alert";
import { makeStyles } from "@mui/styles";
import { FileFlowService } from "../../../../services/FileFlowService";
import MaskCodigo from "../../../../components/MaskCodigo";
import { GeoLocationGeneric } from "../../../../components/GeoLocationGeneric";
import { obtenerDireccionIP } from "../../../../utils";
import { CustomModalDialogGeolocation } from "../../../../components/CustomModalDialogGeolocation";

const Image = styled("img")(({ theme }) => ({
  // width: 100,
  height: 270,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    height: 150,
  },
  [theme.breakpoints.down("xs")]: {
    height: 150,
  },
}));

const useStyles = makeStyles((theme) => ({
  paperResponsive: {
    display: "flex",
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    padding: 0,
    marginLeft: 10,
    marginTop: 11,
    flexWrap: "wrap",
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 0,
      minHeight: "auto",
    },
  },
  botonReponsive: {
    minWidth: '60%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CodigoOTP({ setValueStage, isActiveMassiveSignature }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [valuePrevious, setValuePrevious] = useState('')
  const [codigoOTP, setCodigoOTP] = useState([])
  const [open, setOpen] = useState(false);
  // const [reloadSing, setReloadSing] = useState(false);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [ipDirection, setIpDirection] = useState(null);
  const [minutes, setMinutes] = useState(0);
  const [estadoCorrecto, setEstadoCorrecto] = useState(false);
  const [reloadGeolocation, setReloadGeolocation] = useState(true);
  const [geolocationIP, setGeolocationIP] = useState(false);
  const [openAlerte, setOpenAlerte] = useState(false);
  const [loadingButtonSend, setLoadingButtonSend] = useState(false);

  const userData = useSelector((state) => state.Authentication?.items?.UserLoginData);
  const file = useSelector((state) => state.File.item);

  const [seconds, setSeconds] = useState(0);

  function updateTime() {
    if (minutes === 0 && seconds === 0) {
      setSeconds(0);
      setMinutes(0);
    } else {
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }
  };

  function setInitialTime(totalSeconds) {
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    setMinutes(mins);
    setSeconds(secs);
  }

  useEffect(() => {
    setInitialTime(userData?.EnviromentVars?.TimeResendMinutes); // Set initial time to 90 seconds
  }, [userData?.EnviromentVars?.TimeResendMinutes]);

  useEffect(() => {
    const token = setTimeout(updateTime, 1000);
    return function cleanUp() {
      clearTimeout(token);
    };
  });

  const reenviarCodigo = () => {
    dispatch(notificationPINStageFile({
      Body: {
        File_Id: file.FileData.Id,
        IsMasive: isActiveMassiveSignature
      }
    }));
  };

  const handleClick = (estado) => {
    setOpen(!open);
    setEstadoCorrecto(estado);
  };

  useEffect(() => {
    if (sessionStorage.getItem('OTP')) {
      setValuePrevious(sessionStorage.getItem('OTP'))
      // setValue('OTP', sessionStorage.getItem('OTP'));
    }
  }, []);

  useEffect(() => {
    const obtenerIP = () => {
      obtenerDireccionIP((ip) => {
        if (ip) {
          setIpDirection(ip);
        } else {
          console.log("La dirección IP está vacía o nula. Intentando de nuevo...");
          obtenerIP(); // Llamamos a la función de nuevo si la dirección IP es vacía o nula
        }
      });
    };
    obtenerIP();
  }, []);

  const handleService = async (data) => {
    setLoadingButtonSend(true);
    if (!lat && !lon && (file?.CheckGPS === true || file?.CheckGPS == null || file?.CheckGPS == undefined)) {
      setReloadGeolocation(true)
      setLoadingButtonSend(false);
      return null;
    };
    if (!data || data === undefined) {
      Alert({
        icon: "error",
        text: `Por favor verifica ingresa tu contraseña de un solo uso (OTP).`,
        okbtntext: "Aceptar"
      });
      setLoadingButtonSend(false);
      return null;
    }
    try {
      const fileFlowService = FileFlowService.getInstance();
      const signatureService = SignatureService.getInstance();
      const singPIN = await signatureService.ValidatePINStageSing({
        "File_Id": file.FileData.Id,
        "PIN": data,
        "Latitud": (file?.CheckGPS == null || file?.CheckGPS == undefined || file?.CheckGPS == true) ? lat : null,
        "Longitud": (file?.CheckGPS == null || file?.CheckGPS == undefined || file?.CheckGPS == true) ? lon : null,
        "OriginLocation": (file?.CheckGPS == null || file?.CheckGPS == undefined) ? 'GPS' : file?.CheckGPS === false ? null : geolocationIP === true ? 'IP' : 'GPS',
        "MACAddress": ipDirection !== null && ipDirection !== "" ?
          ipDirection : "000.000.000.000",
        IsMasive: isActiveMassiveSignature,
      });

      if (singPIN?.data?.data?.Body) {
        sessionStorage.removeItem('OTP');
        await fileFlowService.registerStatusStage({
          File_id: file.FileData.Id,
          Status: "Aprovacion Documentos",
          Result: null,
          IsMasive: isActiveMassiveSignature
        });
        dispatch(getFile({ FileId: file.FileData.Id }));
        setLoadingButtonSend(false);
      } else {
        Alert({
          icon: "error",
          text: `Lo sentimos. ${singPIN?.data?.data?.Messages}`,
        });
        setLoadingButtonSend(false);
        sessionStorage.removeItem('OTP');
      }
    } catch (error) {
      setLoadingButtonSend(false);
      sessionStorage.removeItem('OTP');
      Alert({
        icon: "error",
        title: "Error",
        text: `Hubo un error. ${error}`,
      });
      return null;
    }
  };


  useEffect(() => {
    if (geolocationIP === true) {
      setReloadGeolocation(true)
    }
  }, [geolocationIP])


  return (
    <Fragment>
      {((file?.CheckGPS === true || file?.CheckGPS == null || file?.CheckGPS == undefined) && reloadGeolocation) &&
        <GeoLocationGeneric SuccessFN={(latitude, longitude) => {
          console.log("succes,latitude, longitude ", latitude, longitude);
          setLat(latitude);
          setLon(longitude);
        }}
          ErrorFN={(error) => console.log('error', error)}
          setOpenAlerte={setOpenAlerte}
          ReloadGeoLocation={reloadGeolocation}
          setGeolocationIP={setGeolocationIP}
          geolocationIP={geolocationIP}
          setReloadGeoLocation={setReloadGeolocation} />}
      <CustomModalDialogGeolocation Open={openAlerte} setOpenAlerte={setOpenAlerte} setGeolocationIP={setGeolocationIP} />

      <Box className={classes.paperResponsive}>
        <Grid container justifyContent={"center"}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <form onSubmit={handleSubmit(handleService)}>
              <Box sx={{ p: 1 }}>
                <Box sx={{ p: 1 }}>
                  <Typography variant="titulo">
                    Contraseña de un solo uso (OTP)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 1,
                  }}
                >
                  <Typography variant="subtitle" sx={{ textAlign: "justify" }}>
                    Introduce el código numérico que se ha enviado a <b>{userData?.Name} {userData?.FatherLastName ?? ''} {userData?.MotherLastName ?? ''}</b>, al correo  <b> {userData?.EmailFormat ?? ''}</b> y al teléfono <b>{userData?.PhoneNumberMask ?? ''}</b>.
                  </Typography>
                </Box>
                <Box>
                  <Image alt="Celular codigo" src={phoneCodigo} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', p: 2 }}>
                  <Box sx={{ backgroundColor: "secondary.main20", p: 1 }}>
                    <Typography variant="caption">
                      Tiempo para reenvío: 0{minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ pl: 2, pr: 2, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Box>
                  <Box className={classes.botonReponsive}>
                    <MaskCodigo
                      reloadSing={loadingButtonSend}
                      setCodigoOTP={setCodigoOTP}
                      funcionClick={handleService}
                      valuePrevious={valuePrevious}
                    />
                  </Box>
                  <Box className={classes.botonReponsive}>
                    <Box sx={{ textAlign: "center", mt: 1.5 }}>
                      <Button
                        fullWidth
                        variant="outlined"
                        disabled={seconds === 0 ? false : true}
                        sx={{
                          fontSize: "14px !important",
                          height: 38,
                          width: '88%'
                        }}
                        color="primary"
                        onClick={() => reenviarCodigo()}
                      >
                        <Typography sx={{ letterSpacing: 1.2 }}>
                          Reenviar código
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"xs"}
        onClose={handleClick}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              {estadoCorrecto
                ? "Contraseña de un solo uso (OTP) verificada"
                : "Datos introducidos no coinciden"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center", p: 2 }}>
            <Typography sx={{ fontSize: 18 }}>
              {estadoCorrecto
                ? "Ahora puedes iniciar sesión con tu nueva contrasena"
                : "La dirección de correo ----- no se encuentra registrada, vuelve a intentarlo y si no tienes una cuenta en DocSolutions, puedes registrarte ahora"}
            </Typography>
          </Box>
          <Box sx={{ p: 1, borderTop: 1, borderColor: "secondary.main60" }}>
            {estadoCorrecto ? (
              <Button
                fullWidth
                onClick={() => {
                  handleClick();
                  navigate("/");
                }}
                sx={{ fontSize: 16 }}
              >
                Iniciar sesión
              </Button>
            ) : (
              <Box sx={{ display: "flex" }}>
                <Button fullWidth onClick={handleClick} sx={{ fontSize: 14 }}>
                  Reintentar
                </Button>
                <Button fullWidth onClick={handleClick} sx={{ fontSize: 14 }}>
                  Registrarse
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );

}



